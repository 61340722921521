import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox } from "vue"

const _hoisted_1 = { class: "modalpopup" }
const _hoisted_2 = {
  class: "modal",
  "data-backdrop": "static",
  "data-keyboard": "false"
}
const _hoisted_3 = { class: "modal-dialog" }
const _hoisted_4 = { class: "modal-content" }
const _hoisted_5 = { class: "new-modal-header position-relative" }
const _hoisted_6 = { class: "modal-title text-center" }
const _hoisted_7 = {
  key: 0,
  class: "alert alert-success show-copied-message"
}
const _hoisted_8 = { class: "modal-body" }
const _hoisted_9 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "linkInput dvFlex" }
const _hoisted_12 = ["maxlength"]
const _hoisted_13 = ["title"]
const _hoisted_14 = ["src"]
const _hoisted_15 = {
  key: 0,
  class: "regenating-url"
}
const _hoisted_16 = ["src"]
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { class: "form-group" }
const _hoisted_19 = { class: "input-group" }
const _hoisted_20 = { class: "description" }
const _hoisted_21 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_Transition, { name: "modal" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("h4", _hoisted_6, _toDisplayString(_ctx.localDetailModalPopupData.Heading), 1),
                  _createElementVNode("a", {
                    href: "javascript:void(0)",
                    "aria-hidden": "true",
                    class: "cross-icon",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Cancel()))
                  }, _cache[7] || (_cache[7] = [
                    _createElementVNode("i", {
                      class: "fa fa-times close-color",
                      "aria-hidden": "true"
                    }, null, -1)
                  ]))
                ]),
                _createVNode(_Transition, { name: "fade" }, {
                  default: _withCtx(() => [
                    (_ctx.showCopiedMessage)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _cache[8] || (_cache[8] = _createElementVNode("i", {
                            class: "fa fa-check",
                            "aria-hidden": "true"
                          }, null, -1)),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t("LinkCopiedSuccessfully")), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_8, [
                  (!_ctx.isPathValid)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.localDetailModalPopupData.InvalidUrlMessage), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.localDetailModalPopupData.SubHeading1), 1),
                    _createElementVNode("div", _hoisted_11, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localDetailModalPopupData.Path) = $event)),
                        type: "text",
                        class: "form-control",
                        maxlength: _ctx.maxPathLength,
                        onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.GetEncodedPath()))
                      }, null, 40, _hoisted_12), [
                        [_vModelText, _ctx.localDetailModalPopupData.Path]
                      ]),
                      _cache[9] || (_cache[9] = _createTextVNode()),
                      _createElementVNode("div", {
                        title: _ctx.$t('CopyLink'),
                        class: "linkAction cursor-pointer",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.CopyPublicLinkToClipboard()))
                      }, [
                        _createElementVNode("img", { src: _ctx.copiedIcon }, null, 8, _hoisted_14)
                      ], 8, _hoisted_13)
                    ]),
                    (_ctx.urlGenerated)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          _createElementVNode("label", null, _toDisplayString(_ctx.$t("RegeneratingURL")), 1),
                          _createElementVNode("img", {
                            class: "loader-img",
                            src: _ctx.loadingImage,
                            alt: "Wait"
                          }, null, 8, _hoisted_16)
                        ]))
                      : (_openBlock(), _createElementBlock("label", {
                          key: 1,
                          class: "description lbl-path",
                          innerHTML: _ctx.GetPath()
                        }, null, 8, _hoisted_17))
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.localDetailModalPopupData.SubHeading2), 1),
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("label", _hoisted_20, [
                        _withDirectives(_createElementVNode("input", {
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localDetailModalPopupData.HidePageFromSearchEngines) = $event)),
                          type: "checkbox",
                          class: "checkbox-alignment"
                        }, null, 512), [
                          [_vModelCheckbox, _ctx.localDetailModalPopupData.HidePageFromSearchEngines]
                        ]),
                        _createTextVNode(" " + _toDisplayString(_ctx.localDetailModalPopupData.HidePageFromSearchEnginesLabel), 1)
                      ])
                    ])
                  ]),
                  _cache[10] || (_cache[10] = _createElementVNode("hr", { class: "margin-hr" }, null, -1))
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("button", {
                    class: "btn btn-secondary btn-size",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.Cancel()))
                  }, _toDisplayString(_ctx.localDetailModalPopupData.Button1Text), 1),
                  _createElementVNode("button", {
                    class: "btn btn-size btn-primary",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.Save()))
                  }, _toDisplayString(_ctx.localDetailModalPopupData.Button2Text), 1)
                ])
              ])
            ])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}