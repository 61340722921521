import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "modalpopup" }
const _hoisted_2 = {
  class: "modal",
  "data-backdrop": "static",
  "data-keyboard": "false"
}
const _hoisted_3 = { class: "modal-dialog" }
const _hoisted_4 = { class: "modal-content" }
const _hoisted_5 = { class: "new-modal-header position-relative" }
const _hoisted_6 = { class: "font-weight-600 text-center margin-top-0" }
const _hoisted_7 = { class: "modal-body" }
const _hoisted_8 = { class: "font-weight-600 font-size-16" }
const _hoisted_9 = {
  key: 0,
  class: "description"
}
const _hoisted_10 = { class: "width60" }
const _hoisted_11 = {
  key: 1,
  class: "description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_Transition, { name: "modal" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("h4", _hoisted_6, _toDisplayString(_ctx.localAnalyticsModalPopUp.Heading), 1),
                  _createElementVNode("a", {
                    href: "javascript:void(0)",
                    "aria-hidden": "true",
                    class: "cross-icon",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Cancel()))
                  }, _cache[1] || (_cache[1] = [
                    _createElementVNode("i", {
                      class: "fa fa-times close-color",
                      "aria-hidden": "true"
                    }, null, -1)
                  ]))
                ]),
                _createElementVNode("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localAnalyticsModalPopUp.AnalyticsData, (analytics) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: analytics.SubHeading,
                      class: "margin-bottom-20 width45"
                    }, [
                      _createElementVNode("p", _hoisted_8, _toDisplayString(analytics.SubHeading), 1),
                      (analytics.Data.length !== 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(analytics.Data, (item, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: index,
                                class: "display-flex margin-bottom-5"
                              }, [
                                _createElementVNode("div", _hoisted_10, _toDisplayString(item.key), 1),
                                _createElementVNode("div", null, _toDisplayString(item.value), 1)
                              ]))
                            }), 128))
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t('NoDataAvailableYet')), 1))
                    ]))
                  }), 128))
                ])
              ])
            ])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}